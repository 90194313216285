import sanitize from 'sanitize-filename';

export default class FileHelper {

  public static getFileExtension(fileName: string): string {
    const pieces = fileName.split('.');
    if (!pieces || pieces.length < 2) {
      return '';
    }
    return pieces.pop();
  }

  public static isImage(fileName: string): boolean {
    if (!fileName) {
      return false;
    }

    return (/\.(jpg|png|gif|jpe|jpeg|svg)$/i).test(fileName);
  }

  public static isVideo(fileName: string): boolean {
    if (!fileName) {
      return false;
    }
    return (/\.(mpg|mp4|ogv|avi|webm|mkv)$/i).test(fileName);
  }

  public static sanitizeFilename(filename: string): string {
    return sanitize(filename);
  }

  public static downloadFile(file: File, filename: string): void {
    let blob;
    if (typeof File === 'function') {
      try {
        blob = new File([ file ], filename, { type: file.type });
      } catch (e) {
        return;
      }
    }
    if (typeof blob === 'undefined') {
      blob = new Blob([ file ], { type: file.type });
    }

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);

      if (filename) {
        const a = document.createElement('a');

        if (typeof a.download === 'undefined') {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      } else {
        window.location.href = downloadUrl;
      }

      setTimeout(function() {
        URL.revokeObjectURL(downloadUrl);
      }, 100);
    }
  }

}
