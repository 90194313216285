import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TContactsStoreState } from '@/_modules/contacts/types/contacts-store-state.type';
import { TContact } from '@/_types/contact.type';
import contactsApi, {
  TGetEventContactsPageParams,
  TAddContactByCodeParams,
  TPutContactFileParams,
  TDeleteContactFileParams,
  TDeleteContactParams,
} from '@/_api/contacts/contacts.api';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import store from '@/store';

const loadManageContactPageRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const contactsListPageRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const searchContactsRequest = new AxiosCancellableRequest<TGetEventContactsPageParams, TApiListResponse<TContact>>(contactsApi.getEventContactsPage.bind(contactsApi));
const addContactByCodeRequest = new AxiosCancellableRequest<TAddContactByCodeParams, boolean>(contactsApi.addContactByCode.bind(contactsApi));

const contactsStore: Module<TContactsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    contactsById: {},
    contactsByIdRequested: {},
    manageContactsPage: null,
    isManageContactsPageLoading: false,
    lastError: null,
    onlineContactIds: [],
  },
  getters: {

    eventId: (state: TContactsStoreState): number => {
      return state.eventId;
    },

    lastError: (state: TContactsStoreState): Error => {
      return state.lastError;
    },

    contactById: (state: TContactsStoreState) => (contactId: number): TContact => {
      const existingContact = state.contactsById[contactId] || null;

      if (!existingContact && !state.contactsByIdRequested[contactId]) {
        store.dispatch('contactsStore/requestContact', { contactId });
      }

      return existingContact;
    },

    manageContactsPage: (state: TContactsStoreState): TApiListResponse<TContact> => {
      return state.manageContactsPage;
    },

    isManageContactsPageLoading: (state: TContactsStoreState): boolean => {
      return state.isManageContactsPageLoading;
    },

    onlineContactIds: (state: TContactsStoreState): number[] => {
      return state.onlineContactIds;
    }

  },
  actions: {

    reset: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    setEventId: async ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      if (state.eventId === eventId) {
        return;
      }

      commit('setEventId', eventId);
    },

    requestManageContactsPage: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<void> => {

      commit('requestManageContactPage');
      loadManageContactPageRequest.cancel();

      let data;
      try {
        data = await loadManageContactPageRequest.load(params);
        commit('contactsPageReceived', data);
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setManageContactPage', data);
      }
    },

    searchContacts: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<TApiListResponse<TContact>> => {
      searchContactsRequest.cancel();
      let data;
      try {
        data = await searchContactsRequest.load(params);
        commit('contactsPageReceived', data);
        return data;
      } catch (error) {
        commit('setLastError', error);
      }
      return null;
    },

    requestContactsListPage: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TGetEventContactsPageParams): Promise<TApiListResponse<TContact>> => {
      contactsListPageRequest.cancel();
      let data;
      try {
        data = await contactsListPageRequest.load(params);
        commit('contactsPageReceived', data);
        return data;
      } catch (error) {
        commit('setLastError', error);
      }
      return null;
    },

    requestContact: async ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number; force?: boolean }): Promise<void> => {
      const { contactId, force } = params;
      if (!state.eventId) {
        return;
      }
      if (state.contactsById[contactId] && !force) {
        return;
      }

      commit('markContactRequested', contactId);

      try {
        const contact = await contactsApi.getContact({
          eventId: state.eventId,
          contactId: contactId,
        });
        commit('setContact', contact);
      } catch (error) {
        /* ignoring */
      }
    },

    clearContactUnreadMessages: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, contactId: number): void => {
      commit('clearContactUnreadMessages', { contactId });
    },

    addContactByCode: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TAddContactByCodeParams): Promise<boolean> => {

      let responseSuccess = false;
      try {
        responseSuccess = await addContactByCodeRequest.load(params);
      } catch (error) {
        commit('setLastError', error);
      }

      return responseSuccess;

    },

    deleteContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: TDeleteContactParams): void => {
      try {
        contactsApi.deleteContact({ eventId: state.eventId, contactId: params.contactId });
      } catch (error) {
        commit('setLastError', error);
      }

      commit('removeContactById', params.contactId);
    },

    addFavContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      contactsApi.addFavContact({...params, eventId: state.eventId });
      commit('setContactFavorite', { contactId: params.contactId, favState: true});
    },

    removeFavContact: ({ commit, state }: ActionContext<TContactsStoreState, TAppStoreState>, params: { contactId: number }): void => {
      contactsApi.removeFavContact({...params, eventId: state.eventId });
      commit('setContactFavorite', { contactId: params.contactId, favState: false});
    },

    putContactFile: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TPutContactFileParams): Promise<void> => {
      await contactsApi.putContactFile(params)
        .then(() => {
          /* ignore */
        }).catch((e: Error) => {
          commit('setLastError', e);
        });
    },

    deleteContactFile: async ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: TDeleteContactFileParams): Promise<void> => {
      await contactsApi.deleteContactFile(params)
        .then(() => {
          /* ignore */
        }).catch((e: Error) => {
          commit('setLastError', e);
        });
    },

    setOnlineContactIds: ({ commit }: ActionContext<TContactsStoreState, TAppStoreState>, params: number[]): void => {
      commit('setOnlineContactIds', params);
    },

  },
  mutations: {

    setEventId(state: TContactsStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }

      loadManageContactPageRequest.cancel();
      contactsListPageRequest.cancel();

      state.eventId = eventId;
      state.contactsById = {};
      state.contactsByIdRequested = {};
      state.lastError = null;

      state.manageContactsPage = null;
      state.isManageContactsPageLoading = false;
    },

    setLastError(state: TContactsStoreState, lastError: Error): void {
      state.lastError = lastError;
    },

    requestManageContactPage(state: TContactsStoreState): void {
      state.isManageContactsPageLoading = true;
    },

    removeContactById(state: TContactsStoreState, contactId: number): void {
      if (state.contactsById[contactId]) {
        delete state.contactsByIdRequested[contactId];
        delete state.contactsById[contactId];
      }
    },

    setManageContactPage(state: TContactsStoreState, data: TApiListResponse<TContact>): void {
      state.isManageContactsPageLoading = false;

      if (!data) {
        return;
      }

      state.manageContactsPage = data;
    },

    contactsPageReceived(state: TContactsStoreState, data: TApiListResponse<TContact>): void {
      if (!data) {
        return;
      }
      data.List.forEach((contact: TContact): void => {
        state.contactsByIdRequested[contact.id] = true;
        state.contactsById[contact.id] = contact;
      });
    },

    markContactRequested(state: TContactsStoreState, contactId: number): void {
      state.contactsByIdRequested[contactId] = true;
    },

    setContact(state: TContactsStoreState, contact: TContact): void {
      if (!contact) {
        return;
      }
      state.contactsByIdRequested[contact.id] = true;
      state.contactsById = {
        ...state.contactsById,
        [contact.id]: contact,
      };
    },

    setContactFavorite(state: TContactsStoreState, payload: { contactId: number; favState: boolean }): void {
      const { contactId, favState } = payload;
      if (!state.contactsById[contactId]) {
        return;
      }
      state.contactsById[contactId].is_favorite = favState;
    },

    clearContactUnreadMessages(state: TContactsStoreState, payload: { contactId: number }): void {
      const { contactId } = payload;
      if (!state.contactsById[contactId]) {
        return;
      }
      state.contactsById[contactId].unread_messages = 0;
    },

    setOnlineContactIds(state: TContactsStoreState, payload: number[]): void {
      state.onlineContactIds = payload;
    },

  },
};

export default contactsStore;
