import { ActionContext, Module } from 'vuex';
import _union from 'lodash.union';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TChatStoreState } from '@/_modules/chat/types/chat-store-state.type';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import eventDiscoveryService from '@/_services/event-discovery.service';
import { TDiscoveryChatGroupMessagesPageResponse } from '@/_types/discovery/discovery-chat-group-messages-page-response.type';
import { TChatMessage, TChatMessageContext } from '@/_modules/chat/types/chat-message.type';
import { TDiscoveryChatGroupContactsResponse } from '@/_types/discovery/discovery-chat-group-contacts-response.type';
import { TDiscoveryChatGroupContactConnectedResponse } from '@/_types/discovery/discovery-chat-group-contact-connected-response.type';
import { TDiscoveryChatGroupContactDisconnectedResponse } from '@/_types/discovery/discovery-chat-group-contact-disconnected-response.type';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import textChatsApi, { TGetTextChatsParams } from '@/_api/text-chats.api';

const MESSAGES_CHUNK_SIZE = 20;

const chatStore: Module<TChatStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    config: { eventId: null, contactId: null },
    chatGroups: {},
    textChats: [],
    textChatsById: {}
  },
  getters: {

    isConfigured: (state: TChatStoreState): boolean => {
      return state.config.eventId !== null && state.config.contactId !== null;
    },

    getChatGroupStateByGroupId: (state: TChatStoreState) => (groupId: string): TChatGroupState => {
      return state.chatGroups[groupId] || null;
    },

    textChats: (state: TChatStoreState): TTextChat[] => {
      return state.textChats;
    },

    textChatsById: (state: TChatStoreState) => (textChatId: number): TTextChat => {
      return state.textChatsById[textChatId] || null;
    },

  },
  actions: {

    configure: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, config: { eventId: number; contactId: number }): void => {
      commit('configure', config);
    },

    getTextChats: async ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, params: TGetTextChatsParams): Promise<void> => {

      let chatList: TTextChat[] = null;

      try {
        chatList = await textChatsApi.getTextChats(params);
      } catch(e) {
        /* ignore */
      }

      commit('setTextChats', chatList);
    },

    enterChatGroup: ({ commit, state }: ActionContext<TChatStoreState, TAppStoreState>, groupId: string): void => {
      const chatGroupState = state.chatGroups[groupId];
      if (chatGroupState) {
        return;
      }
      commit('enterChatGroup', groupId);
      eventDiscoveryService.enterChatGroup(groupId);
      commit('chatGroupEntered', groupId);
    },

    requestChatGroupMessagesPage: ({ commit, state }: ActionContext<TChatStoreState, TAppStoreState>, groupId: string): void => {
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState || chatGroupState.isMessagesLoading) {
        return;
      }

      const lastMessageId = chatGroupState.messages.length ? chatGroupState.messages[0].id : undefined;
      commit('requestChatGroupMessagesPage', groupId);
      eventDiscoveryService.requestChatGroupMessagesPage(groupId, MESSAGES_CHUNK_SIZE, lastMessageId);
    },

    chatGroupMessagesPageResponse: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, response: TDiscoveryChatGroupMessagesPageResponse): void => {
      commit('chatGroupMessagesPageResponse', response);
    },

    requestChatGroupContacts: ({ commit, state }: ActionContext<TChatStoreState, TAppStoreState>, groupId: string): void => {
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      commit('requestChatGroupContacts', groupId);
      eventDiscoveryService.requestChatGroupContacts(groupId);
    },

    chatGroupContactsResponse: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, response: TDiscoveryChatGroupContactsResponse): void => {
      commit('chatGroupContactsResponse', response);
    },

    chatGroupContactConnectedResponse: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, response: TDiscoveryChatGroupContactConnectedResponse): void => {
      commit('chatGroupContactConnectedResponse', response);
    },

    chatGroupContactDisconnectedResponse: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, response: TDiscoveryChatGroupContactDisconnectedResponse): void => {
      commit('chatGroupContactDisconnectedResponse', response);
    },

    chatGroupMessage: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, message: TChatMessage): void => {
      commit('chatGroupMessage', message);
    },

    sendChatGroupTextMessage: ({ state }: ActionContext<TChatStoreState, TAppStoreState>, params: { groupId: string; message: string; context: TChatMessageContext }): void => {
      const { groupId, message, context } = params;
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      eventDiscoveryService.sendChatGroupTextMessage(groupId, message, context);
    },

  },
  mutations: {

    configure(state: TChatStoreState, config: { eventId: number; contactId: number }): void {
      const { eventId, contactId } = config;
      if (state.config.eventId === eventId && state.config.contactId === contactId) {
        return;
      }
      state.config = {
        eventId,
        contactId,
      };
      state.chatGroups = {};
    },

    setTextChats(state: TChatStoreState, chatList: TTextChat[]): void {
      state.textChats = chatList;
      state.textChatsById = {};
      if (state.textChats) {
        state.textChats.forEach((textChat: TTextChat) => {
          state.textChatsById[textChat.id] = textChat;
        });
      }
    },

    enterChatGroup(state: TChatStoreState, groupId: string): void {
      state.chatGroups[groupId] = {
        groupId: groupId,
        isEntered: false,
        enterError: null,
        messages: [],
        isMessagesLoading: false,
        messagesError: null,
        isAllMessagesLoaded: false,
        contactsIds: [],
        isContactsRequested: false,
        isContactsLoading: false,
        contactsError: null,
      };
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupEntered(state: TChatStoreState, groupId: string): void {
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.isEntered = true;
      chatGroupState.enterError = null;
      state.chatGroups = { ...state.chatGroups };
    },

    requestChatGroupMessagesPage(state: TChatStoreState, groupId: string): void {
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.isMessagesLoading = true;
      chatGroupState.messagesError = null;
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupMessagesPageResponse(state: TChatStoreState, response: TDiscoveryChatGroupMessagesPageResponse): void {
      const { groupId, limit, messages } = response;
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      if (messages.length) {
        chatGroupState.messages = [ ...messages.reverse(), ...chatGroupState.messages ];
      }
      if (!messages.length || messages.length < limit) {
        chatGroupState.isAllMessagesLoaded = true;
      }
      chatGroupState.isMessagesLoading = false;
      chatGroupState.messagesError = null;
      state.chatGroups = { ...state.chatGroups };
    },

    requestChatGroupContacts(state: TChatStoreState, groupId: string): void {
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.isContactsLoading = true;
      chatGroupState.isContactsRequested = true;
      chatGroupState.contactsError = null;
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupContactsResponse(state: TChatStoreState, response: TDiscoveryChatGroupContactsResponse): void {
      const { groupId, contactIds } = response;
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.isContactsLoading = false;
      chatGroupState.contactsError = null;
      chatGroupState.contactsIds = _union(chatGroupState.contactsIds, contactIds);
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupContactConnectedResponse(state: TChatStoreState, response: TDiscoveryChatGroupContactConnectedResponse): void {
      const { groupId, contactId } = response;
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.contactsIds = _union(chatGroupState.contactsIds, [ contactId ]);
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupContactDisconnectedResponse(state: TChatStoreState, response: TDiscoveryChatGroupContactDisconnectedResponse): void {
      const { groupId, contactId } = response;
      const chatGroupState = state.chatGroups[groupId];
      if (!chatGroupState) {
        return;
      }
      const index = chatGroupState.contactsIds.indexOf(contactId);
      if (index < 0) {
        return;
      }
      chatGroupState.contactsIds.splice(index, 1);
      chatGroupState.contactsIds = [ ...chatGroupState.contactsIds ];
      state.chatGroups = { ...state.chatGroups };
    },

    chatGroupMessage(state: TChatStoreState, message: TChatMessage): void {
      const { to } = message;
      const chatGroupState = state.chatGroups[to];
      if (!chatGroupState) {
        return;
      }
      chatGroupState.contactsIds = _union(chatGroupState.contactsIds, [ message.from ]);
      chatGroupState.messages = [ ...chatGroupState.messages, message ];
      state.chatGroups = { ...state.chatGroups };
    },

  },
};

export default chatStore;
