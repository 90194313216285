import Vue from 'vue';
import moment from 'moment';
import { DateTimeFormat } from '@/_types/date-time-format.enum';

export default class DateTimeHelper {

  public static getStartOfTheDayMoment(): moment.Moment {
    return Vue.moment.utc().subtract(0, 'days').startOf('day');
  }

  public static isJSDate(input: any): boolean {
    return input instanceof Date;
  }

  public static getFullDate(date: Date): string {
    return moment(date).format('YYYY-MM-DDTHH:mm'); // TODO: check this date format in meetings
  }
  public static getFullDateTime(date: Date): string {
    return moment(date).format(DateTimeFormat.API_DATE_SMALL);
  }

  public static getDateWithoutHoursMinutes(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  public static getHoursMinutes(date: Date): string {
    return moment(date).format('HH:mm');
  }

  public static getMomentFromUtcString(dateTimeStr: string): moment.Moment {
    return moment(dateTimeStr).utc(true);
  }

  public static messageTime(inputTime: moment.MomentInput): string {
    if (!inputTime) {
      return '';
    }
    return moment(inputTime).format(DateTimeFormat.HOURS_MINUTES);
  }

  public static messageDay(inputTime: moment.MomentInput, localeName?: string): string {
    if (!inputTime) {
      return '';
    }
    let formatString: string = DateTimeFormat.DAY_NAME_DATE_MONTH_EN;
    if (localeName === 'uk' || localeName === 'ru') {
      formatString = DateTimeFormat.DAY_NAME_DATE_MONTH_UK;
    }
    return moment(inputTime).format(formatString);
  }

  public static newsPublicationDate(inputTime: moment.MomentInput, localeName?: string): string {
    if (!inputTime) {
      return '';
    }
    let formatString: string = DateTimeFormat.NEWS_PUBLICATION_DATE_EN;
    if (localeName === 'uk' || localeName === 'ru') {
      formatString = DateTimeFormat.NEWS_PUBLICATION_DATE_UK;
    }
    return moment(inputTime).format(formatString);
  }

  public static apiDateToDate(apiDate: string): Date {
    return (apiDate && (new Date(apiDate))) || null;
  }

  public static dateToApiDate(date: moment.MomentInput): string {
    // TODO: use Date.toISOString()!
    // TODO: do not cut seconds and milliseconds
    return (date && moment(date).utc(false).format(DateTimeFormat.API_DATE_SMALL)) || null;
  }

  public static getDateDiff(date1: Date, date2: Date): number {
    const one_day = 1000 * 3600 * 24;
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );
    const days = (utc2 - utc1) / one_day;
    return Math.abs(days);
  }

}
