export enum ContactsFilterType {
  ALL = 'all',
  MY = 'my',
  BOOTH_VIEWERS = 'booth-viewers'
}

export type TContactsFilter = {
  type: ContactsFilterType;
  search: string;
  tagIds: number[];
};
