<template>
  <div class="relative dropdown">
    <div class="title">
      <span
          class="inline-block relative cur-p lang-flag"
          :class="'lang-flag-' + lang"
      ></span>
      {{ $t('shortLanguageNames.' + lang) }}
    </div>
    <div class="dropdown-content" ref="otherLanguagesList">
      <a
          v-for="(lang, index) in otherLanguages"
          :key="`lang${index}`"
          @click="changeLanguage(lang)"
      >
        <span class="inline-block relative cur-p lang-flag lang-flag-noarrow" :class="'lang-flag-' + lang.value"></span>
        {{ $t('shortLanguageNames.'+lang.value) }}
      </a>
    </div>
  </div>
</template>
<script lang="js">
import Vue from 'vue';

const LanguagePicker = Vue.extend({
  name: 'language-picker',
  computed: {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    otherLanguages() {
      return this.languages.filter((x) => x.value !== this.lang);
    },
  },
  data() {
    return {
      languages: [
        {
          title: this.$t('languages.english'),
          value: 'en',
        },
        {
          title: this.$t('languages.russian'),
          value: 'ru',
        },
        {
          title: this.$t('languages.lithuanian'),
          value: 'lt',
        },
        {
          title: this.$t('languages.chinese'),
          value: 'zh',
        },
        {
          title: this.$t('languages.ukrainian'),
          value: 'uk',
        },
        {
          title: this.$t('languages.vietnamese'),
          value: 'vi',
        },
        {
          title: this.$t('languages.bulgarian'),
          value: 'bg',
        },
        {
          title: this.$t('languages.albanian'),
          value: 'sq',
        },
      ],
      lang: this.$i18n.locale,
    };
  },
  methods: {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    changeLanguage(lang) {
      this.lang = lang.value;
      Vue.localStorage.set('language', this.lang);

      this.$router
        .push({name: this.$route.name, params: {lang: this.lang}})
        .then(() => {
          this.$router.go(0); // router.go performs page reload, 0 = zero pages back in history
        });

      try {
        // change html lang="" — helpful for lots of things
        window.document.documentElement.lang = this.lang;
      } catch (error) {
        /* nothing */
      }
    },
  },
});

export default LanguagePicker;
</script>

<style scoped lang="scss">
.title {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 6px;
    border-style: solid;
    border-width: 0 1px 1px 0;
    border-color: #bbb;
    transform: translate(0, -75%) rotate(45deg);
    width: 8px;
    height: 8px;
  }

  &-noarrow::after {
    display: none;
  }
}

.lang-flag {
  width: 14px;
  height: 14px;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100%;

  &-en {
    background-image: url("~@/assets/images/lang-flag-en.png");
  }

  &-ru {
    background-image: url("~@/assets/images/lang-flag-ru.png");
  }

  &-uk {
    background-image: url("~@/assets/images/lang-flag-uk.png");
  }

  &-zh {
    background-image: url("~@/assets/images/lang-flag-zh.png");
  }

  &-lt {
    background-image: url("~@/assets/images/lang-flag-lt.png");
  }

  &-vi {
    background-image: url("~@/assets/images/lang-flag-vi.png");
  }

  &-bg {
    background-image: url("~@/assets/images/lang-flag-bg.png");
  }

  &-sq {
    background-image: url("~@/assets/images/lang-flag-sq.png");
  }
}

.dropdown .dropbtn {
  padding: 14px 16px;
  margin: 0;
  font-family: inherit;
  font-size: 16px;
  color: white;
  background-color: inherit;
  border: none;
  outline: none;
}

.dropdown-content {
  position: absolute;
  left: 0;
  margin-left: -16px;
  z-index: 1000;
  display: none;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);

  /* Invisible active area over language list, so that it does not disappear when hovered over improperly */
  &::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 16px;

    .section-mainnav-event & {
      bottom: auto;
      top: 100%;
    }

  }

  .section-mainnav-event & {
    bottom: 100%;
  }
}

.dropdown-content a {
  cursor: pointer;
  display: block;
  float: none;
  padding: 12px 16px;
  color: black !important;
  text-align: left;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: #ddd;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
