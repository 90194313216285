import api from '../../api/index';

export default {
  strict: true,
  namespaced: true,
  state: {
    eventId: null,
    planJson: '',
    jsonError: '',
    isLoading: false,
  },
  getters: {
    isPlanJson (state) {
      return state.planJson !== null;
    }
  },
  mutations: {
    setEventId(state, eventId) {
      state.eventId = eventId;
      state.planJson = '';
      state.jsonError = '';
      state.isLoading = false;
    },
    setJson(state, data) {
      if (!data.data) {
        state.planJson = null;
      } else {
        state.planJson = JSON.stringify(data.data);
      }
    },
    сlearJson(state) {
      state.planJson = '';
    },
    jsonError(state, data) {
      state.jsonError = data
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    }
  },
  actions: {
    getSeatplanJson: async function ({ commit, state }, { eventId }) {
      if (state.eventId === eventId) {
        return;
      }

      commit('setEventId', eventId);
      commit('setIsLoading', true);
      await api.seatPlan.getSeatPlanJson({ eventId })
        .then((resolve) => {
          commit('setJson', resolve);
          commit('setIsLoading', false);
        }).catch(() => {
          // commit('jsonError', e.data);
          commit('setIsLoading', false);
        });
    },
    clearSeatplanJson: async function ({commit}) {
      commit('сlearJson');
    }
  }
}

