import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TabNames, TSideBarRightStoreState} from '@/_modules/promo/types/side-bar-right-store-state.type.ts';
import { TNote } from '@/_types/store/note.type';
import notesApi, { TGetNotesParams } from '@/_api/notes/notes.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

const getNotesRequest = new AxiosCancellableRequest<TGetNotesParams, TApiListResponse<TNote>>(notesApi.getNotes.bind(notesApi));

const sideBarRightStore: Module<TSideBarRightStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isOpen: false,
    isSideBarRightComponentPresent: false,
    activeTabName: TabNames.NONE,
    selectedContactNotes: [],
    isUserSettingsOpen: false,
    isQRCodePopupVisible: false
  },
  getters: {

    isOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isOpen;
    },

    isSideBarRightComponentPresent: (state: TSideBarRightStoreState): boolean => {
      return state.isSideBarRightComponentPresent;
    },

    activeTabName: (state: TSideBarRightStoreState): TabNames => {
      return state.activeTabName;
    },

    contactNotes: (state: TSideBarRightStoreState): TNote[] => {
      return state.selectedContactNotes;
    },

    isUserSettingsOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isUserSettingsOpen;
    },
    isQRCodePopupVisible: (state: TSideBarRightStoreState): boolean => {
      return state.isQRCodePopupVisible;
    },
  },
  actions: {

    open: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('open');
    },

    close: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('close');
    },

    toggle: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('toggle');
    },

    setActiveTab: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, tabName: TabNames): void => {
      commit('setActiveTab', tabName);
    },

    clearActiveTab: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('setActiveTab', TabNames.NONE);
    },

    setComponentPresence: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setComponentPresence', value);
    },

    getContactNotes: async ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, params: TGetNotesParams): Promise<void> => {
      const { eventId, queryParams } = params;

      let data;
      try {
        data = await getNotesRequest.load({ eventId, queryParams });
        commit('setNotes', data.List);
      } catch (error) {
        commit('setNotes', []);
      }
    },

    setIsUserSettingsOpen: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsUserSettingsOpen', value);
    },
    setIsQRCodePopupVisible: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>, value: boolean): void => {
      commit('setIsQRCodePopupVisible', value);
    },
  },
  mutations: {

    open(state: TSideBarRightStoreState): void {
      state.isOpen = true;
    },

    close(state: TSideBarRightStoreState): void {
      state.isOpen = false;
    },

    toggle(state: TSideBarRightStoreState): void {
      state.isOpen = !state.isOpen;
    },

    setActiveTab(state: TSideBarRightStoreState, tabName: TabNames): void {
      state.activeTabName = tabName;
    },

    setComponentPresence(state: TSideBarRightStoreState, value: boolean): void {
      state.isSideBarRightComponentPresent = value;
    },

    setNotes(state: TSideBarRightStoreState, value: TNote[]): void {
      state.selectedContactNotes = value;
    },

    setIsUserSettingsOpen(state: TSideBarRightStoreState, value: boolean): void {
      state.isUserSettingsOpen = value;
    },
    setIsQRCodePopupVisible(state: TSideBarRightStoreState, value: boolean): void {
      state.isQRCodePopupVisible = value;
    }

  },
};

export default sideBarRightStore;
