import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

export const APP_AVAILABLE_LANGUAGES: string[] = [ 'en', 'ru', 'lt', 'zh', 'uk', 'vi', 'bg', 'sq' ];

function attemptToUseBrowserLocale(): string {
  const browserLocale = navigator.language ? navigator.language.split(/[_-]/)[0] : null;
  if (!browserLocale || (APP_AVAILABLE_LANGUAGES.indexOf(browserLocale) === -1)) {
    return null;
  }
  return browserLocale;
}

const BROWSER_LOCALE: string = attemptToUseBrowserLocale();

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || Vue.localStorage.get('language') || BROWSER_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Vue.localStorage.get('language') || BROWSER_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

export default i18n;
