
// TODO: refactoring - split into separate files

export enum EventAccessType {
  FREE = 'free',
  REGISTER = 'register',
  TICKET = 'ticket',
  INVITE = 'invite',
  CASH = 'cash'
}

/* TODO: remove this comment if unneeded
 export type TEventPersonal = {
  notes: number;
  contacts: number;
  tickets: number;
  is_creator: boolean;
  is_favorite: boolean;
  is_going: boolean;
  has_access: boolean;
}

export type TEventMap = {
  CountryName: string;
  CityName: string;
  Location: string;
  Latitude: string;
  Longitude: string;
}

export type TEvent = {
  id: number;
  title: string;
  date_start: string;
  date_end: string;
  access_type: EventAccessType;
  is_private: boolean;
  image: string;
  org_logo: string;
  address: string;
  personal: TEventPersonal;
  creator_user_id: number;
  event_domain: string;
  languages: string[];
} */

export type TCountry = {
  id?: number;
  name?: string;
  iso2?: string;
  iso3?: string;
  phonecode?: string;
  capital?: string;
  currency?: string;
}

export type TCity = {
  id?: number;
  name?: string;
  state_id?: number;
  country_id?: number;
}

export type TEventType = {
  id?: number;
  name?: string;
}

export type TCategory ={
  id?: number;
  name?: string;
}

export type TPersonalData = {
  notes?: number;
  contacts?: number;
  tickets?: number;
  is_creator?: boolean;
  is_favorite?: boolean;
  is_going?: boolean;
  has_access?: boolean;
}

export type TEventMap = {
  country_name?: string;
  city_name?: string;
  location?: string;
  longitude?: string;
  latitude?: string;
}

export type TEvent = {
  id?: number;
  title: string;
  descr?: string;
  image?: string;
  date_start: Date;
  date_end: Date;
  url?: string;
  buy_ticket_url?: string;
  buy_booth_url?: string;
  photos?: string[];
  address?: string;
  country?: TCountry;
  city?: TCity;
  agendas?: string;
  org_name?: string;
  org_phone?: string;
  org_email?: string;
  org_logo?: string;
  access_type?: EventAccessType;
  event_type?: TEventType;
  categories?: TCategory[];
  personal?: TPersonalData;
  is_private?: boolean;
  is_enabled?: boolean;
  map?: TEventMap;
  creator_user_id?: number;
  languages?: string[];
  event_domain?: string;
  time_region: string;
}

export type TEventSettings = {
  event_id: number;
  layout: any;
  show_meetings?: boolean;
}

/*
{
  "id":161,
  "title":"Roman Test Event Two",
  "date_start":"2020-08-17T11:00:00Z",
  "date_end":"2020-09-30T20:00:00Z",
  "event_type":{},
  "access_type":"register",
  "is_private":true,
  "image":"https://biletum.s3-eu-west-1.amazonaws.com/88/ad9c9f5d-82bc-4c3e-9251-ddfa9932a768.jpg",
  "org_logo":"",
  "address":"вулиця Велика Васильківська, 55, Київ, 02000",
  "personal":{
    "notes":4,
    "contacts":1,
    "tickets":0,
    "is_creator":true,
    "is_favorite":false,
    "is_going":true,
    "has_access":true
  },
  "map":{
    "CountryName":null,
    "CityName":null,
    "Location":null,
    "Latitude":"",
    "Longitude":""
    },
  "creator_user_id":88
}
 */
