






















import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import helpCrunchService, { THelpCrunchConfig } from '@/_services/help-crunch.service';
import Navigation from '@/_components/navigation/navigation.vue';
import AuthPopup from '@/_components/auth-popup/auth-popup.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import CookieConsentBox from '@/_components/cookie-consent-box/cookie-consent-box.vue';
import MobileAppInviter from '@/_components/mobile-app-inviter/mobile-app-inviter.vue';
import EventHeadPanel from '@/_modules/promo/components/event-head-panel/event-head-panel.vue';
import LanguagePicker from '@/_components/language-picker/language-picker.vue';
import notificationsService from '@/_modules/promo/services/notifications.service';

// TODO: ALMOST DONE get rid of id="app" - see public/index.html - use "app" classname instead

@Component({
  name: 'App', // should be the only exception with CamelCase
  components: {
    EventHeadPanel,
    Navigation,
    CookieConsentBox,
    AuthPopup,
    MobileAppInviter,
    LanguagePicker
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthPopupVisible: 'authStore/isAuthPopupVisible',
      user: '_userStore/user',
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      promoPage: 'promoPageStore/promoPage',
      isSideBarLeftOpen: 'sideBarLeftStore/isOpen',
      isSideBarRightOpen: 'sideBarRightStore/isOpen',
      isSideBarRightComponentPresent: 'sideBarRightStore/isSideBarRightComponentPresent'
    }),
  }
})
export default class App extends Vue {

  public isAuthenticated: boolean;
  public readonly isAuthPopupVisible: boolean;
  public user: TUser;
  public event: TEvent;
  public contact: TContact;
  public promoPage: TPromoPage;
  public readonly isSideBarRightOpen: boolean;

  public async created(): Promise<void> {
    this.setDocumentLangAttribute();
    Vue.moment.locale(this.$i18n.locale);

    helpCrunchService.locale = this.$i18n.locale;
    this.configureHelpCrunch();
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get helpCrunchConfig(): THelpCrunchConfig {
    return {
      user: this.user,
      event: this.event,
      contact: this.contact,
      promoPage: this.promoPage,
    };
  }

  public get isEventHeadPanelVisible(): boolean {
    return !!this.$route.matched.find(route => route.path.indexOf('/:lang/events/:eventId') >= 0);
  }

  public get isNavigationVisible(): boolean {

    if (this.isEventHeadPanelVisible) {
      return false;
    }

    if (
      this.$route.meta
      && typeof this.$route.meta.showNavigation !== 'undefined'
      && !this.$route.meta.showNavigation
    ) {
      return false;
    }

    return (
      this.$route.name
      // TODO: we already have meta.showNavigation - change route config instead
      && this.$route.name.indexOf('promo') < 0
      && this.$route.name !== 'notes-list'
      && this.$route.name.indexOf('result') < 0
    );
  }

  // TODO: check usage, remove this, phase it out. Seems to be not needed anymore. Has been added for customizations
  public get additionalRootElementClasses(): { [key: string]: boolean } {
    const result: { [key: string]: boolean } = {};
    if (!this.$route) {
      return result;
    }

    const replaces = [
      [ /\[:eventId]/g, this.$route.params.eventId ],
    ];

    if (this.$route.name) {
      result['app-' + this.$route.name] = true;
    }

    if (this.$route.meta && this.$route.meta.rootElementClasses) {
      for (let i = 0; i < this.$route.meta.rootElementClasses.length; i++) {
        let classNameString = this.$route.meta.rootElementClasses[i];
        classNameString = ((str): string => {
          for (let repl = 0; repl < replaces.length; repl++) {
            str = str.replace(replaces[repl][0], replaces[repl][1]);
          }
          return str;
        })(classNameString);
        result[classNameString] = true;
      }
    }
    return result;
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('eventId', { immediate: true })
  private _onEventIdChange(): void {
    this.refreshEventStore();
    this.refreshPromoPageStore();
    this.configureNotificationsService();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(): void {
    this.configureNotificationsService();
    this.refreshNotificationsStore();
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('isAuthenticated', { immediate: true })
  private onIsAuthenticatedChange(): void {
    this.refreshUserStore();
    this.refreshPromoPageStore();
  }

  @Watch('helpCrunchConfig', { immediate: true })
  private onHelpCrunchConfigChange(): void {
    this.configureHelpCrunch();
  }

  @Watch('isSideBarLeftOpen', { immediate: true })
  private onIsSideBarLeftOpen(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if(!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    const className = 'root-side-bar-left-open';
    if (newVal) {
      docEl.classList.add(className);
      return;
    }
    docEl.classList.remove(className);
  }

  @Watch('isSideBarRightOpen', { immediate: true })
  private onIsSideBarRightOpen(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if(!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    const className = 'root-side-bar-right-open';
    if (newVal) {
      docEl.classList.add(className);
      return;
    }
    docEl.classList.remove(className);
  }

  @Watch('isSideBarRightComponentPresent', { immediate: true })
  private onIsSideBarRightComponentPresentChange(newVal: boolean): void {
    const doc: HTMLDocument = document;
    if(!document || !document.documentElement) {
      return;
    }
    const docEl: HTMLElement = doc.documentElement;
    if (newVal) {
      docEl.classList.add('root-side-bar-right-present');
      docEl.classList.remove('root-side-bar-right-absent');
      return;
    }
    docEl.classList.add('root-side-bar-right-absent');
    docEl.classList.remove('root-side-bar-right-present');
  }

  private setDocumentLangAttribute(): void {
    document.documentElement.lang = this.$i18n.locale;
  }

  private configureHelpCrunch(): void {
    helpCrunchService.configure(this.helpCrunchConfig);
  }

  private configureNotificationsService(): void {
    notificationsService.configure({
      eventId: this.eventId,
      contactId: (this.contact && this.contact.id) || null,
      userId: (this.user && this.user.id) || null,
    });
  }

  private refreshUserStore(): void {
    if (this.isAuthenticated) {
      this.$store.dispatch('_userStore/getUser');
    } else {
      this.$store.dispatch('_userStore/reset');
    }
  }

  private refreshEventStore(): void {
    if (this.eventId) {
      this.$store.dispatch('_eventStore/getEvent', this.eventId);
      // TODO: remove old store request
      this.$store.dispatch('eventStore/event', this.$route.params.eventId);
    } else {
      this.$store.dispatch('_eventStore/reset');
    }
  }

  private refreshPromoPageStore(): void {
    if (this.isAuthenticated && this.eventId) {
      this.$store.dispatch('promoPageStore/getContact', this.eventId);
      this.$store.dispatch('promoPageStore/getPromoPage', this.eventId);
    } else {
      this.$store.dispatch('promoPageStore/reset');
    }
  }

  private refreshNotificationsStore(): void {
    if (!this.eventId) {
      return;
    }
    this.$store.dispatch('notificationsStore/getNotificationList', this.eventId);
  }

}
