import {
  NotificationListResponseItemType,
  TNotificationListResponseItem
} from '@/_api/notifications/notifications.api';
import {TNotification} from '@/_modules/promo/types/notification.type';
import {NotificationType} from '@/_modules/promo/types/notification-type.enum';
import DateTimeHelper from '@/_helpers/date-time.helper';
import {TContact} from '@/_types/contact.type';
import {TMessage} from '@/_types/messages.type';
import {TMeeting} from '@/_types/meeting/meeting.type';
import ContactHelper from '@/_helpers/contact.helper';
import _cloneDeep from 'lodash.clonedeep';

export default class NotificationsHelper {

  public static prepareBadgeNotificationNumber(count: number): string {
    // limit is 99
    return count <= 99 ? ('' + count) : ('99+');
  }

  private static getIdPrefix(obj: TNotificationListResponseItem): string {
    let result: string;

    switch (obj.type) {
      case NotificationListResponseItemType.NEW_MESSAGE:
        result = 'message';
        break;
      case NotificationListResponseItemType.MEETING_REQUEST:
      case NotificationListResponseItemType.MEETING_CONFIRMED:
      case NotificationListResponseItemType.MEETING_CANCELED:
      case NotificationListResponseItemType.MEETING_REMINDER:
        result = 'meeting';
        break;
      default:
        result = 'unknownEntityType';
    }

    return result;
  }

  private static getNotificationType(obj: TNotificationListResponseItem): NotificationType {
    let result: NotificationType;

    switch (obj.type) {
      case NotificationListResponseItemType.NEW_MESSAGE:
        result = NotificationType.CONTACT_MESSAGE;
        break;
      case NotificationListResponseItemType.MEETING_REQUEST:
        result = NotificationType.CONTACT_MEETING_REQUEST;
        break;
      case NotificationListResponseItemType.MEETING_CONFIRMED:
        result = NotificationType.MEETING_IS_CONFIRMED;
        break;
      case NotificationListResponseItemType.MEETING_CANCELED:
        result = NotificationType.MEETING_IS_CANCELED;
        break;
      case NotificationListResponseItemType.MEETING_REMINDER:
        result = NotificationType.CONTACT_MEETING_REMINDER;
        break;
      default:
        result = NotificationType.NONE;
    }

    return result;
  }

  private static getNotificationContact(obj: TNotificationListResponseItem): TContact {
    let result: TContact;
    switch (obj.type) {
      case NotificationListResponseItemType.NEW_MESSAGE:
        result = _cloneDeep((obj.context as TMessage).contact);
        break;
      case NotificationListResponseItemType.MEETING_REQUEST:
      case NotificationListResponseItemType.MEETING_CONFIRMED:
      case NotificationListResponseItemType.MEETING_CANCELED:
      case NotificationListResponseItemType.MEETING_REMINDER:
        result = _cloneDeep((obj.context as TMeeting).contact);
        break;
      default:
        return null;
    }

    if (!result.fullName) {
      result.fullName = ContactHelper.getFullName(result);
    }
    if (!result.fullCompany) {
      result.fullCompany = ContactHelper.getFullCompany(result);
    }
    return result;
  }

  private static getNotificationMeeting(obj: TNotificationListResponseItem): TMeeting {
    let result: TMeeting;
    switch (obj.type) {
      case NotificationListResponseItemType.NEW_MESSAGE:
        result = null;
        break;
      case NotificationListResponseItemType.MEETING_REQUEST:
      case NotificationListResponseItemType.MEETING_CONFIRMED:
      case NotificationListResponseItemType.MEETING_CANCELED:
      case NotificationListResponseItemType.MEETING_REMINDER:
        result = _cloneDeep(obj.context as TMeeting);
        if (!result.contact.fullName) {
          result.contact.fullName = ContactHelper.getFullName(result.contact);
        }
        if (!result.contact.fullCompany) {
          result.contact.fullCompany = ContactHelper.getFullCompany(result.contact);
        }
        break;
      default:
        result = null;
    }
    return result;
  }

  public static responseToNotificationListConverter(responseList: TNotificationListResponseItem[]): TNotification[] {
    if(!responseList) return null;
    const result: TNotification[] = [];
    responseList.forEach((item) => {
      if (item.context) {
        result.push({
          id: this.getIdPrefix(item) + '_' + item.context.id,
          entityId: item.context.id,
          type: this.getNotificationType(item),
          time: item.created_at ? DateTimeHelper.getMomentFromUtcString(item.created_at) : null,
          skipPopup: true,
          contact: this.getNotificationContact(item),
          meeting: this.getNotificationMeeting(item),
        });
      }
    });
    return result;
  }

}
