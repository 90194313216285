import { ActionContext, Module } from 'vuex';
import { TNotificationsStoreState } from '@/_modules/promo/types/notifications-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TNotification } from '@/_modules/promo/types/notification.type';
import { NotificationType } from '@/_modules/promo/types/notification-type.enum';
import { TDiscoveryWaitingMeetingNotificationResponse } from '@/_types/discovery/discovery-waiting-meeting-notification-response.type';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';
import notificationsApi from '@/_api/notifications/notifications.api';

export const POPUP_NOTIFICATIONS_LIMIT = 5;
export const POPUP_NOTIFICATION_INTERVAL_BETWEEN = 1250;
export const POPUP_NOTIFICATION_DISAPPEAR_TIMEOUT = 10000;
export const POPUP_NOTIFICATION_ANIMATION_TIME = 400;

const notificationsStore: Module<TNotificationsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    popupNotifications: [],
    waitingMeetingNotification: null,
    newsArticleNotification: null,
    notifications: [],
    messagesCount: 0,
    messagesMap: new Map<number, boolean>(),
    noticedMessagesCount: 0,
    noticedMessagesMap: new Map<number, boolean>(),
    meetingsCount: 0,
    meetingsMap: new Map<number, boolean>(),
    noticedMeetingsCount: 0,
    noticedMeetingsMap: new Map<number, boolean>(),
    confirmedMeetingsMap: new Map<number, boolean>(),
    canceledMeetingsMap: new Map<number, boolean>(),
    broadcastsMap: new Map<number, boolean>(),
    noticedBroadcastsMap: new Map<number, boolean>(),
    conferenceProgramsMap: new Map<number, boolean>(),
    noticedConferenceProgramsMap: new Map<number, boolean>(),
  },
  getters: {

    popupNotifications: (state: TNotificationsStoreState): TNotification[] => {
      return state.popupNotifications;
    },

    notifications: (state: TNotificationsStoreState): TNotification[] => {
      return state.notifications;
    },

    waitingMeetingNotification: (state: TNotificationsStoreState): TDiscoveryWaitingMeetingNotificationResponse => {
      return state.waitingMeetingNotification;
    },

    newsArticleNotification: (state: TNotificationsStoreState): TDiscoveryNewsArticleNotificationResponse => {
      return state.newsArticleNotification;
    },

    messagesCount: (state: TNotificationsStoreState): number => {
      return state.messagesCount;
    },

    noticedMessagesCount: (state: TNotificationsStoreState): number => {
      return state.noticedMessagesCount;
    },

    meetingsCount: (state: TNotificationsStoreState): number => {
      return state.meetingsCount;
    },

    noticedMeetingsCount: (state: TNotificationsStoreState): number => {
      return state.noticedMeetingsCount;
    },

  },
  actions: {

    reset: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>): void => {
      commit('reset');
    },

    getNotificationList: async ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      commit('reset');

      let data: TNotification[];
      try {
        data = await notificationsApi.getNotificationsList(eventId);
      } catch {
        /* ignore */
      }

      if (data) {
        data.forEach((item) => {
          commit('push', item);
        });
      }
    },

    push: ({ commit, state }: ActionContext<TNotificationsStoreState, TAppStoreState>, notification: TNotification ): void => {
      if (
        notification.type === NotificationType.CONTACT_MESSAGE
        && state.messagesMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.CONTACT_MEETING_REQUEST
        && state.meetingsMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.CONTACT_MEETING_REMINDER
        && state.meetingsMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.NEW_COMPANY_BROADCAST_STARTED
        && state.broadcastsMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.CONFERENCE_PROGRAM_STARTED
        && state.conferenceProgramsMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.MEETING_IS_CONFIRMED
        && state.confirmedMeetingsMap.get(notification.entityId)
      ) {
        return;
      } else if (
        notification.type === NotificationType.MEETING_IS_CANCELED
        && state.canceledMeetingsMap.get(notification.entityId)
      ) {
        return;
      }

      commit('push', notification);
      if (!notification.skipPopup) {

        const delayedStart = 10 + POPUP_NOTIFICATION_INTERVAL_BETWEEN * state.popupNotifications.length;

        setTimeout(() => {
          commit('update', { id: notification.id, isFading: false });
        }, delayedStart);
        setTimeout(() => {
          commit('update', { id: notification.id, isFading: true });
          setTimeout(() => {
            commit('popupRemove', notification.id);
          }, POPUP_NOTIFICATION_ANIMATION_TIME);
        }, delayedStart + POPUP_NOTIFICATION_DISAPPEAR_TIMEOUT);
      }
      if (state.popupNotifications.length >= POPUP_NOTIFICATIONS_LIMIT) {
        for (let i = state.popupNotifications.length - 1; i >= (POPUP_NOTIFICATIONS_LIMIT - 1); i--) {
          const oldNotification = state.popupNotifications[i];
          commit('update', { id: oldNotification.id, isFading: true });
          setTimeout(() => {
            commit('popupRemove', oldNotification.id);
          }, POPUP_NOTIFICATION_ANIMATION_TIME);
        }
      }
    },

    setWaitingMeetingNotification: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, payload: TDiscoveryWaitingMeetingNotificationResponse): void => {
      commit('setWaitingMeetingNotification', payload);
    },

    setNewsArticleNotification: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, payload: TDiscoveryNewsArticleNotificationResponse): void => {
      commit('setNewsArticleNotification', payload);
    },

    remove: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, id: string): void => {
      commit('update', { id: id, isFading: true });
      commit('remove', id);
      setTimeout(() => {
        commit('popupRemove', id);
      }, POPUP_NOTIFICATION_ANIMATION_TIME);
    },

    popupRemove: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, id: string): void => {
      commit('update', { id: id, isFading: true });
      setTimeout(() => {
        commit('popupRemove', id);
      }, POPUP_NOTIFICATION_ANIMATION_TIME);
    },

    setMessagesCount: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, messagesCount: number): void => {
      commit('setMessagesCount', messagesCount);
    },

    incrementMessagesCount: ({ commit, getters }: ActionContext<TNotificationsStoreState, TAppStoreState>): void => {
      commit('setMessagesCount', getters.messagesCount + 1);
    },

    incrementMeetingsCount: ({ commit, getters }: ActionContext<TNotificationsStoreState, TAppStoreState>): void => {
      commit('setMeetingsCount', getters.meetingsCount + 1);
    },

    setNoticedMessages: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, messageIds: number[]): void => {
      commit('setNoticedMessages', messageIds);
    },

    setMeetingsCount: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, meetingsCount: number): void => {
      commit('setMeetingsCount', meetingsCount);
    },

    setNoticedMeetings: ({ commit }: ActionContext<TNotificationsStoreState, TAppStoreState>, meetingIds: number[]): void => {
      commit('setNoticedMeetings', meetingIds);
    },

    // simulate: ({ dispatch }: ActionContext<TNotificationsStoreState, TAppStoreState>): void => {
    //
    //   let count = 0;
    //
    //   setTimeout(() => {
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MESSAGE,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MEETING_REQUEST,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MESSAGE,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MEETING_REQUEST,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MESSAGE,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MEETING_REQUEST,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MESSAGE,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MEETING_REQUEST,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MESSAGE,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       time: moment(),
    //       type: NotificationType.CONTACT_MEETING_REQUEST,
    //       contact: {
    //         id: 1,
    //         fullName: 'fullName fullName fullName fullName fullName fullName fullName fullName',
    //         fullCompany: 'fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany fullCompany',
    //         photo_url: 'https://fr-prod-1301920146.cos.eu-frankfurt.myqcloud.com/37/34098490-c094-4198-bfec-d17dd3353bc3.jpg',
    //       },
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       entityId: Math.random(),
    //       type: NotificationType.NEW_COMPANY_BROADCAST_STARTED,
    //       time: moment(),
    //       external_id: 'simulation_external_id',
    //       skipPopup: false,
    //     });
    //
    //     dispatch('push', {
    //       id: 'simulation-' + count++,
    //       entityId: Math.random(),
    //       type: NotificationType.CONFERENCE_PROGRAM_STARTED,
    //       time: moment(),
    //       isFading: false,
    //       skipPopup: false,
    //     });
    //
    //   }, 2000);
    //
    //   // window.setInterval(() => {
    //   //   const newNotification: TNotification = {
    //   //     id: 'simulation-' + count++,
    //   //     time: moment(),
    //   //   };
    //   //   dispatch('push', newNotification);
    //   // }, 4000);
    //
    // },

  },
  mutations: {

    reset(state: TNotificationsStoreState): void {
      state.popupNotifications = [];
      state.notifications = [];
      state.messagesCount = 0;
      state.meetingsCount = 0;
    },

    popupRemove(state: TNotificationsStoreState, id: string): void {
      state.popupNotifications.splice(state.notifications.findIndex(item => item.id === id), 1);
    },

    remove(state: TNotificationsStoreState, id: string): void {
      state.notifications.splice(state.notifications.findIndex(item => item.id === id), 1);
    },

    update(state: TNotificationsStoreState, partial: TNotification): void {
      if (!partial.id) {
        return;
      }
      const notification = state.notifications.find(item => item.id === partial.id);
      if (!notification) {
        return;
      }
      Object.assign(notification, partial);
    },

    push(state: TNotificationsStoreState, notification: TNotification): void {
      notification.isFading = true;
      if (!notification.skipPopup && state.popupNotifications.length < POPUP_NOTIFICATIONS_LIMIT) {
        state.popupNotifications.unshift(notification);
      }
      state.notifications.unshift(notification);
      if (notification.type === NotificationType.CONTACT_MESSAGE) {
        state.messagesMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.CONTACT_MEETING_REQUEST) {
        state.meetingsMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.CONTACT_MEETING_REMINDER) {
        state.meetingsMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.NEW_COMPANY_BROADCAST_STARTED) {
        state.broadcastsMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.CONFERENCE_PROGRAM_STARTED) {
        state.conferenceProgramsMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.MEETING_IS_CONFIRMED) {
        state.confirmedMeetingsMap.set(notification.entityId, true);
      } else if (notification.type === NotificationType.MEETING_IS_CANCELED) {
        state.canceledMeetingsMap.set(notification.entityId, true);
      }
    },

    setWaitingMeetingNotification(state: TNotificationsStoreState, payload: TDiscoveryWaitingMeetingNotificationResponse): void {
      state.waitingMeetingNotification = payload;
    },

    setNewsArticleNotification(state: TNotificationsStoreState, payload: TDiscoveryNewsArticleNotificationResponse): void {
      state.newsArticleNotification = payload;
    },

    setMessagesCount(state: TNotificationsStoreState, messagesCount: number): void {
      state.messagesCount = messagesCount;
    },

    setNoticedMessages(state: TNotificationsStoreState, messageIds: number[]): void {
      if (!messageIds || !messageIds.length) {
        return;
      }
      messageIds.forEach(messageId => {
        if (state.noticedMessagesMap.get(messageId) || !state.messagesMap.get(messageId)) {
          return;
        }
        state.noticedMessagesMap.set(messageId, true);
        state.noticedMessagesCount = state.noticedMessagesCount + 1;
      });
    },

    setMeetingsCount(state: TNotificationsStoreState, meetingsCount: number): void {
      state.meetingsCount = meetingsCount;
    },

    setNoticedMeetings(state: TNotificationsStoreState, meetingIds: number[]): void {
      if (!meetingIds || !meetingIds.length) {
        return;
      }
      meetingIds.forEach(meetingId => {
        if (state.noticedMeetingsMap.get(meetingId) || !state.meetingsMap.get(meetingId)) {
          return;
        }
        state.noticedMeetingsMap.set(meetingId, true);
        state.noticedMeetingsCount = state.noticedMeetingsCount + 1;
      });
    },

  },
};

export default notificationsStore;
