import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import NotificationsHelper from '@/_helpers/notifications.helper';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import {TMessage} from '@/_types/messages.type';
import {TMeeting} from '@/_types/meeting/meeting.type';
import {TNotification} from '@/_modules/promo/types/notification.type';

export type TGetUnreadCountParams = {
  eventId: number;
}

export type TGetUnreadCountResponse = {
  messages_count?: number;
  meeting_count?: number;
  // upcoming_meeting: ?
}

export enum NotificationListResponseItemType {
  NEW_MESSAGE = 'new_message',
  MEETING_CONFIRMED = 'meeting_confirmed',
  MEETING_REQUEST = 'meeting_request',
  MEETING_CANCELED = 'meeting_canceled',
  MEETING_REMINDER = 'meeting_reminder',
}

export type TNotificationListResponseItem = {
  id: number;
  type: NotificationListResponseItemType;
  created_at: string;
  context: TMessage | TMeeting;
}

export class NotificationsApi extends BaseApi {

  public async getNotificationsList(eventId: number, cancelToken?: CancelToken): Promise<TNotification[]> {
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/notification/list`,
      method: 'GET',
      params: {
        limit: 50,
      },
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<TApiListResponse<TNotificationListResponseItem>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return NotificationsHelper.responseToNotificationListConverter(response.data.List);
  }

}

const notificationsApi = new NotificationsApi();
export default notificationsApi;
