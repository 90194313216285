


import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import IconPasswordEye from '@/_modules/icons/components/icon-password-eye.vue';
import { Prop, Vue, Watch, Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TPasswordChangeConfirmRequestParams, TSendLoginRequestParams } from '@/_api/login/login.api';
import { Route} from 'vue-router';
import { EventAccessType, TEvent } from '@/_types/event.type';
import { AuthScreenStep } from '@/_types/auth-screen-step.enum';
import HelpCrunchService from '@/_services/help-crunch.service';
import { TranslateResult } from 'vue-i18n';

class ConfirmCodeDigit {

  public value: string = '';

  public get isValid(): boolean {
    return /^[0-9]{1}$/.test(this.value);
  }

  public setCleanValue(): void {
    this.value = this.value.replace(/[^0-9]/g, '').substr(0, 1);
  }

}

@Component({
  components: {
    IconArrowLeft,
    IconPasswordEye
  },
  computed: {
    ...mapState('authStore', {
      authStatus: 'status',
      authError: 'authError',
      forgotPasswordRequestStatus: 'forgotPasswordRequestStatus',
      forgotPasswordRequestError: 'forgotPasswordRequestError',
      changePasswordRequestStatus: 'changePasswordRequestStatus',
      changePasswordRequestError: 'changePasswordRequestError',
    }),
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading',
      authPopupTargetRoute: 'authStore/authPopupTargetRoute',
      event: '_eventStore/event',
    }),
    ...mapState(
      'eventStore', ['showPromoCheckAccessPopup']
    ),
  },
})
export default class AuthContent extends Vue {

  public readonly event: TEvent;
  public readonly authStatus: string;
  public readonly authError: ApiErrorResponseData;
  public readonly isAuthLoading: boolean;
  public readonly isAuthenticated: boolean;
  public readonly authPopupTargetRoute: Route;
  public readonly forgotPasswordRequestStatus: string;
  public readonly forgotPasswordRequestError: ApiErrorResponseData;
  public readonly changePasswordRequestStatus: string;
  public readonly changePasswordRequestError: ApiErrorResponseData;
  public readonly showPromoCheckAccessPopup: boolean;

  public AuthScreenStep: typeof AuthScreenStep = AuthScreenStep;
  public visibleAuthScreen: AuthScreenStep = AuthScreenStep.PHONE_EMAIL_INPUT;

  // User credentials input-related
  public userLogin: string = '';
  public userPassword: string = '';
  public loginIsIncorrect: TranslateResult = '';

  // Global flag for programmatic enable|disable the send user credentials.
  // See computed isSendLoginButtonDisabled() for complete picture
  public isSendLoginButtonEnabled: boolean = true;

  // Confirmation code-related
  public confirmCode: string = '';
  public confirmCodeDigits: ConfirmCodeDigit[] = [
    new ConfirmCodeDigit(),
    new ConfirmCodeDigit(),
    new ConfirmCodeDigit(),
    new ConfirmCodeDigit(),
  ];

  public errorText: string = '';
  public errorConfirmText: TranslateResult = '';
  public count: number = 10;
  public counter: number = null;
  public isResendButtonDisabled: boolean = false;
  public resendEnablerTimeoutId: number = -1;

  // Stores the ticket code provided if the event needs it
  public eventAccessCode: string = '';
  public isEventAccessCodeSending: boolean = false;
  public eventAccessCodeError: TranslateResult = '';

  // The email or phone to where the forgot password email will be sent
  public forgotPasswordAddress: string = '';

  public newPassword: string = '';

  // Password visibility flag for the main password input. Usage is in the computed getter 'passwordInputTypeAttribute'
  public passwordVisibilityEnterPassword: boolean = false;

  // Password visibility flag for the main password input. Usage is in the computed getter 'newPasswordInputTypeAttribute'
  public passwordVisibilityNewPassword: boolean = false;

  @Prop({default: false}) public readonly isInPopup: boolean;

  public get isProgressIndicatorVisible(): boolean {
    const isAuthLoading = this.authStatus === 'loading';
    const isForgotPasswordLoading = this.forgotPasswordRequestStatus === 'loading';
    const isChangePasswordLoading = this.changePasswordRequestStatus === 'loading';
    return isAuthLoading || this.isEventAccessCodeSending || isForgotPasswordLoading || isChangePasswordLoading;
  }

  public get authErrorText(): TranslateResult {
    if (!this.authError) {
      return '';
    }
    let translationKeyName = 'unknown';
    switch (this.authError.code) {
      case 14:
        translationKeyName = 'wrongLoginOrPassword';
        break;
      case 7:
        translationKeyName = 'forbiddenByFirewall';
        break;
      default:
        if (this.authError.error) {
          return this.authError.error;
        }
    }
    return this.$t('authPage.screens.phoneEmail.errors.' + translationKeyName);
  }

  public get passwordInputTypeAttribute(): string {
    if (this.passwordVisibilityEnterPassword) {
      return 'text';
    }
    return 'password';
  }

  public get newPasswordInputTypeAttribute(): string {
    if (this.passwordVisibilityNewPassword) {
      return 'text';
    }
    return 'password';
  }

  public get isSendLoginButtonDisabled(): boolean {
    const isUserLoginFilled: boolean = this.userLogin !== '';
    const isUserPasswordFilled: boolean = this.userPassword !== '';
    const isUserLoginIncorrect: boolean = this.loginIsIncorrect !== '';
    return !this.isSendLoginButtonEnabled || !isUserLoginFilled || !isUserPasswordFilled || isUserLoginIncorrect;
  }

  public get isSendYourCodeButtonDisabled(): boolean {
    return this.eventAccessCode === '';
  }

  public get isForgotPasswordButtonDisabled(): boolean {
    return this.forgotPasswordAddress === '';
  }

  public get isChangePasswordButtonDisabled(): boolean {
    const isNewPasswordEmpty: boolean = this.newPassword === '';
    const isConfirmCodeInvalid: boolean = this.validateConfirmCode() === false;
    return isNewPasswordEmpty || isConfirmCodeInvalid;
  }

  public get deviceId(): string {
    return this.$store.state.authStore.deviceId;
  }

  public get forgotPasswordEmailError(): string {
    return (this.forgotPasswordRequestError && this.forgotPasswordRequestError.error) ? this.forgotPasswordRequestError.error : '';
  }

  public get confirmId(): string {
    return this.$store.state.authStore.confirmId;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  /* Watches the change in authStatus,
   * performs needed routines upon successful login,
   */
  @Watch('authStatus', {immediate: false})
  private async onAuthStatusChange(newVal: string): Promise<void> {

    if (newVal === 'success') {
      // Access check when there is an event
      if (this.$route.params.eventId && this.event && this.event.id && !this.event.personal.has_access) {

        await this.runAuthRoutines();
        return;

      }

      await this.authRedirectToSavedRoute();

    }
  }

  @Watch('changePasswordRequestError')
  private onChangePasswordRequestError(newVal: ApiErrorResponseData): void {
    if (newVal) {
      this.errorConfirmText = this.$t('authPage.screens.changePassword.errors.' + (newVal.error.trim() || 'unknown'));
    } else {
      this.errorConfirmText = '';
    }
  }

  public async mounted(): Promise<void> {
    // Mounted hook is needed for the case when
    // a user tried to enter smth via a URL, i.e. /promo/
    // User is then being redirected to EventPage with «show popup» flag
    // authPopup gets triggered because of the flag, and we have to check:
    // -- if the user is already logged in, show them the eventAccessCode screen
    if (this.isAuthenticated && this.$route.params.eventId && this.event && this.event.personal.has_access !== true) {
      await this.runAuthRoutines();
    }
  }

  /* Free admission? Set «i will go» to true
   * Else show the «EventAccessCode screen» */
  private async runAuthRoutines(): Promise<void> {
    if (this.event.access_type === EventAccessType.FREE) {
      await this.$store.dispatch('eventStore/toggleParticipation', {
        event_id: this.$route.params.eventId,
        going: true
      });
      await this.authRedirectToSavedRoute();
    } else {
      // We have to update personal.has_access to check it below
      await this.$store.dispatch('_eventStore/reset');
      await this.$store.dispatch('_eventStore/getEvent', this.eventId);

      if (this.event.personal.has_access) {
        await this.authRedirectToSavedRoute();
        return;
      }
      this.showEventAccessCodeScreen();
    }
  }

  /* Whatever actions need to be done on keyup in userLogin, userPassword */
  private eventAccessCodeKeyupHandler(): void {
    // hide the error if the code changed
    this.eventAccessCodeError = '';
    this.eventAccessCode = this.eventAccessCode.trim();
  }

  /* Handlers for keyup.enter — keyboard UX, AW-2390 */
  private onSignInScreenKeyupEnter(): void {
    this.sendLogin();
  }

  private onTicketCodeScreenKeyupEnter(): void {
    this.checkEventCode();
  }

  private onForgotPasswordScreenKeyupEnter(): void {
    this.forgotPassword();
  }

  private onConfirmCodeScreenKeyupEnter(): void {
    this.changePassword();
  }

  /* Navigate to the saved route after authPopup routines
   * if no route is saved, just close the popup
   */
  private async authRedirectToSavedRoute(): Promise<void> {
    if (this.isInPopup) {
      // Auth content is in the popup
      if (this.authPopupTargetRoute) {
        try {
          await this.$router.push(this.authPopupTargetRoute);
        } catch {
          /* ignore */
        }
      }
      await this.hideAuthPopup();
    } else {
      // Auth content is in the page
      if (this.$route.params.redirect) {
        try {
          await this.$router.push(this.$route.params.redirect);
        } catch {
          /* ignore */
        }
      } else {
        try {
          await this.$router.push({name: 'home'});
        } catch {
          /* ignore */
        }
      }
    }

  }

  /* Shows the phone input screen, removes the confirm id
   * Click handler for the div.back
   */
  private authBackToPhoneInput(): void {
    this.visibleAuthScreen = AuthScreenStep.PHONE_EMAIL_INPUT;
    this.forgotPasswordAddress = '';
    this.disableResendButton();
    this.clearResendEnabler();
    this.$store.dispatch('authStore/removeConfirmId');
  }

  private dispatchUserLoginPreprocessing(): void {
    const value: string = this.userLogin.trim();

    const isLikePhone: boolean = value.indexOf('@') < 0;

    if (isLikePhone) {
      this.preprocessUserLoginAsPhoneNumber(value);
    } else {
      this.validateUserLoginAsEmailAddress(value);
    }
  }

  private preprocessUserLoginAsPhoneNumber(value: string): void {

    // AW-1127 attach «+» to phones
    value = value.replace(/[^\d]/g, '');
    if (value) {
      value = '+' + value;
    } else {
      this.loginIsIncorrect = this.$t('authPage.screens.phoneEmail.errors.usePhoneOrEmail');
    }

    this.userLogin = value.trim();

  }

  private validateUserLoginAsEmailAddress(value: string): void {

    let isValid = true;

    if (
      ((value.match(/@/g) || []).length > 1)
      || value === '@'
      || (value.indexOf('..') >= 0)
      || /(^\.)|(^@)|(\.@)|(@\.)|(@-)|(\.$)|(@$)|(-$)/.test(value)
      || /\..{2,}$/.test(value) === false
      || /\..{2,}$/.test(value) === false
      || /\.\d+$/.test(value)
    ) {
      isValid = false;
    }

    if (!isValid) {
      this.loginIsIncorrect = this.$t('authPage.screens.phoneEmail.errors.usePhoneOrEmail');
    }

  }

  /* Sends login and password from the first step */
  private async sendLogin(): Promise<void> {

    this.dispatchUserLoginPreprocessing(); // Can result in isSendLoginButtonDisabled = true, thus stands before the check

    if (this.isSendLoginButtonDisabled) {
      return;
    }

    const payload: TSendLoginRequestParams = {
      login: this.userLogin,
      password: this.userPassword,
      platform: 'WEB',
      device_id: this.deviceId,
      // device_token: deviceToken,
    };

    await this.$store.dispatch('authStore/sendLogin', payload);

  }

  /* Checks the user-provided event code (ticket code, promocode, etc.) */
  private async checkEventCode(): Promise<void> {
    this.eventAccessCodeError = '';
    this.isEventAccessCodeSending = true;
    const response = await this.$store.dispatch('eventStore/activateCode', {
      event_id: this.$route.params.eventId,
      code: this.eventAccessCode
    });
    this.isEventAccessCodeSending = false;
    if (response && response.status === 202) {
      await this.authRedirectToSavedRoute();
      await this.$store.dispatch('_eventStore/refresh');
      await this.$store.dispatch('promoPageStore/refresh'); // AW-1622
    } else {
      this.eventAccessCodeError = this.$t('promoAccessCheck.accessNotGranted');
    }
  }

  private showEventAccessCodeScreen(): void {
    this.visibleAuthScreen = AuthScreenStep.EVENT_ACCESS_CODE_INPUT;
  }

  private disableResendButton(): void {
    this.isResendButtonDisabled = true;
  }

  private clearResendEnabler(): void {
    window.clearTimeout(this.resendEnablerTimeoutId);
    this.resendEnablerTimeoutId = -1;
    this.disableResendButton();
  }

  private validateConfirmCode(): boolean {
    // Reducing the array of objects into a string made from each object.value
    const fullCode = this.confirmCodeDigits
      .reduce((acc, item) => acc + item.value, '')
      .replace(/[^0-9]/g, '');
    if (fullCode.length === 4) {
      this.confirmCode = fullCode;
      return true;
    }
    return false;
  }

  /* Some routines for keyup on login field.
   * I.e. clear errors if a key has been pressed in the field
   */
  private userLoginKeyupHandler(): void {
    this.loginIsIncorrect = '';
    this.$store.dispatch('authStore/clearAuthError');
  }

  /* Some routines for keyup on password field.
   * I.e. clear errors if a key has been pressed in the field
   */
  private userPasswordKeyupHandler(): void {
    this.$store.dispatch('authStore/clearAuthError');
  }

  private confirmDigitKeyupHandler(event: Event, index: number): void {

    // Perform value cleanup
    this.confirmCodeDigits[index].setCleanValue();

    // focus into the next code digit, if it exists
    this.$nextTick(() => {
      const vueRefsAsArray = (this.$refs.authConfirmationDigit as Array<HTMLElement>);
      if (this.confirmCodeDigits[index].value !== '' && vueRefsAsArray[index + 1]) {
        (vueRefsAsArray[index + 1] as HTMLElement).focus();
      }
    });

  }

  private prevFocus(index: number): void {
    if (this.confirmCodeDigits[index - 1]) {
      const vueRefsAsArray = (this.$refs.authConfirmationDigit as Array<HTMLElement>);
      (vueRefsAsArray[index - 1] as HTMLElement).focus();
    }
    this.confirmCodeDigits[index].value = '';
    this.errorConfirmText = '';
  }

  private hideAuthPopup(): void {
    this.$store.dispatch('authStore/setAuthPopupVisible', {
      isAuthPopupVisible: false,
      targetRoute: null
    });
  }

  private togglePasswordVisibility(refName: string): void {
    this.$nextTick(() => {
      if (!this.$refs[refName]) {
        return;
      }

      switch (refName) {
        case 'passwordInput':
          this.passwordVisibilityEnterPassword = !this.passwordVisibilityEnterPassword;
          break;
        case 'newPasswordInput':
          this.passwordVisibilityNewPassword = !this.passwordVisibilityNewPassword;
          break;
        default:
          this.passwordVisibilityEnterPassword = !this.passwordVisibilityEnterPassword;
      }

    });
  }

  private showForgotPasswordScreen(): void {
    this.visibleAuthScreen = AuthScreenStep.FORGOT_PASSWORD;
  }

  private showChangePasswordScreen(): void {
    this.visibleAuthScreen = AuthScreenStep.CHANGE_FORGOTTEN_PASSWORD;
  }

  private async forgotPassword(): Promise<void> {
    this.confirmCode = '';
    this.confirmCodeDigits = [
      new ConfirmCodeDigit(),
      new ConfirmCodeDigit(),
      new ConfirmCodeDigit(),
      new ConfirmCodeDigit(),
    ];
    this.errorConfirmText = '';

    if (this.isForgotPasswordButtonDisabled) {
      return;
    }

    await this.$store.dispatch('authStore/forgotPassword', this.forgotPasswordAddress);

    if (this.forgotPasswordRequestStatus === 'success') {
      this.disableResendButtonTemporarily();
      this.showChangePasswordScreen();
    }
  }

  private disableResendButtonTemporarily(milliseconds: number = 31000): void { // half a minute plus a little bit
    this.isResendButtonDisabled = true;
    this.resendEnablerTimeoutId = window.setTimeout(this.enableResendButton, milliseconds);
  }

  private enableResendButton(): void {
    this.isResendButtonDisabled = false;
  }

  private async changePassword(): Promise<void> {

    // N.B.: this.confirmCode gets populated in validateConfirmCode
    if (!this.validateConfirmCode() || this.newPassword === '') {
      return;
    }

    const payload: TPasswordChangeConfirmRequestParams = {
      confirmation_id: this.confirmId,
      code: this.confirmCode,
      password: this.newPassword,
      platform: 'WEB',
      device_id: this.deviceId,
    };
    await this.$store.dispatch('authStore/changePassword', payload);
  }

  /* AW-1055 */
  private openSupportChat(event: Event): void {
    if (event && event.target) {
      const clickedElement: HTMLElement = event.target as HTMLElement;
      if (clickedElement.classList.contains('link')) {
        const helpCrunchInstance = HelpCrunchService._helpCrunch;
        if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
          window.setTimeout(() => { this.openSupportChat(event); }, 1000);
          return;
        }
        helpCrunchInstance('openChat');
      }
    }
  }

}
