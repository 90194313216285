import { AxiosRequestConfig, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import PromoProgramHelper from '@/_modules/promo-program/helpers/promo-program-helper';

export type TGetConferenceRoomsParams = {
  eventId: number;
};

export type TCreateConferenceProgramParams = {
  event_id: number;
  conference_id: number;
  title: string;
  date_start: string;
  date_end: string;
  poster_url: string;
  description: string;
  video_stream_embed: string;
  vod_url: string;
  show_live_chat: boolean;
  show_speaker_chat: boolean;
};

export type TPatchConferenceProgramParams = {
  id: number;
  event_id: number;
  conference_id: number;
  title?: string;
  date_start?: string;
  date_end?: string;
  poster_url?: string;
  description?: string;
  video_stream_embed?: string;
  vod_url?: string;
  show_live_chat?: boolean;
  show_speaker_chat?: boolean;
};

export type TAddConferenceProgramSpeakerParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  contact_id: number;
};

export type TRemoveConferenceProgramSpeakerParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  contact_id: number;
};

export type TAddConferenceProgramFileParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  url: string;
  filename: string;
};

export type TAddConferenceProgramSponsorParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  photo_url: string;
  link: string;
  sponsor_id?: number;
};

export type TDeleteConferenceProgramSponsorParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  sponsor_id: number;
}

export type TDeleteConferenceProgramFileParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  file_id: number;
}

export type TRemoveConferenceRoomParams = {
  event_id: number;
  conference_id: number;
};

export type TRemoveConferenceProgramParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
};

export type TToggleProgramFavoriteParams = {
  event_id: number;
  conference_id: number;
  program_id: number;
  is_favorite: boolean;
};

export type TConferenceProgramRating = {
  rating: number;
}

export class PromoProgramApi extends BaseApi {

  public async getConferenceRooms(params: TGetConferenceRoomsParams, cancelToken?: CancelToken): Promise<TConferenceRoom[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/conference/list/all`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data.List.map(item => {
      return PromoProgramHelper.responseToConferenceRoomsConverter(item);
    });
  }

  public async createConferenceRoom(conferenceRoom: TConferenceRoom): Promise<TConferenceRoom> {
    const config: AxiosRequestConfig = {
      url: `/event/${conferenceRoom.event_id}/conference`,
      method: 'POST',
      params: Object.assign({}, conferenceRoom, { event_id: undefined }),
    };

    let response;
    try {
      response = await this.axios.request<any>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return PromoProgramHelper.responseToConferenceRoomsConverter(response.data);
  }

  public async patchConferenceRoom(conferenceRoom: TConferenceRoom): Promise<TConferenceRoom> {
    const config: AxiosRequestConfig = {
      url: `/event/${conferenceRoom.event_id}/conference/${conferenceRoom.id}`,
      method: 'PATCH',
      params: Object.assign({}, conferenceRoom, { event_id: undefined, id: undefined }),
    };

    let response;
    try {
      response = await this.axios.request<TConferenceRoom>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async createConferenceProgram(params: TCreateConferenceProgramParams): Promise<TConferenceProgram> {
    // const sentData = PromoProgramHelper.requestToConferenceProgramConverter(params);
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program`,
      method: 'POST',
      params: Object.assign({}, params, { event_id: undefined, conference_id: undefined }),
    };

    let response;
    try {
      response = await this.axios.request<TConferenceProgram>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return PromoProgramHelper.responseToConferenceProgramConverter(response.data);
  }

  public async patchConferenceProgram(params: TPatchConferenceProgramParams): Promise<void> {
    // const sentData = PromoProgramHelper.requestToConferenceProgramConverter(params);
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.id}`,
      method: 'PATCH',
      params: Object.assign({}, params, { id: undefined, event_id: undefined, conference_id: undefined }),
    };

    try {
      await this.axios.request<TConferenceProgram>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async addConferenceProgramSpeaker(params: TAddConferenceProgramSpeakerParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/contact`,
      method: 'PUT',
      params: {
        contact_id: params.contact_id,
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async deleteConferenceProgramSpeaker(params: TRemoveConferenceProgramSpeakerParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/contact`,
      method: 'DELETE',
      params: {
        contact_id: params.contact_id,
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async addConferenceProgramFile(params: TAddConferenceProgramFileParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/file`,
      method: 'PUT',
      params: {
        url: params.url,
        filename: params.filename,
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async addConferenceProgramSponsor(params: TAddConferenceProgramSponsorParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/sponsor`,
      method: 'POST',
      params: {
        photo_url: params.photo_url,
        link: params.link,
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async removeConferenceRoom(params: TRemoveConferenceRoomParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async deleteConferenceProgramFile(params: TDeleteConferenceProgramFileParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/file`,
      method: 'DELETE',
      params: {
        file_id: params.file_id,
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async deleteConferenceProgramSponsor(params: TDeleteConferenceProgramSponsorParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/sponsor/${params.sponsor_id}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async removeConferenceProgram(params: TRemoveConferenceProgramParams): Promise<void> {
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}`,
      method: 'DELETE',
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async setProgramFavorite(params: TToggleProgramFavoriteParams): Promise<void> {
    const method = params.is_favorite ? 'PUT' : 'DELETE';
    const config: AxiosRequestConfig = {
      url: `/event/${params.event_id}/conference/${params.conference_id}/program/${params.program_id}/fav`,
      method: method,
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async getConferenceProgramRating(params: {eventId: number; conferenceId: number; programId: number}): Promise<TConferenceProgramRating> {
    const { eventId, conferenceId, programId } = params;

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/conference/${conferenceId}/program/${programId}/rating`,
    };

    let response;
    try {
      response = await this.axios.request<TConferenceProgramRating>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  };

  public async patchConferenceProgramRating(params: {eventId: number; conferenceId: number; programId: number; rating: number}): Promise<void> {
    const { eventId, conferenceId, programId, rating } = params;

    if (rating < 1 || rating > 5) {
      return;
    }

    const config: AxiosRequestConfig = {
      method: 'PATCH',
      url: `/event/${eventId}/conference/${conferenceId}/program/${programId}/rating`,
      params: {
        rating
      }
    };

    try {
      await this.axios.request<any>(config);
    } catch (error) {
      /* ignore */
    }

  }

}

const promoProgramApi = new PromoProgramApi();
export default promoProgramApi;
