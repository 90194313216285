


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
import momentTimezone from 'moment-timezone';
// @ts-ignore
import LanguagePicker from '@/_components/language-picker/language-picker';

@Component({
  name: 'event-head-panel',
  components: {
    LanguagePicker
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      isAuthenticated: 'authStore/isAuthenticated',
    }),
  },
})
export default class EventHeadPanel extends Vue {

  // DATA
  public isTimezoneHintVisible: boolean = false;
  public isLogoutInProgress: boolean = false;

  // COMPUTED
  public readonly event: TEvent;
  public readonly isAuthenticated: boolean;

  public get endDateNeeded(): boolean {
    return this.$moment(this.event.date_start).format('LL') !== this.$moment(this.event.date_end).format('LL');
  }

  public get isLogoutButtonVisible(): boolean {
    return this.isAuthenticated && this.$route.name === 'event-info';
  }

  public get isLogoutButtonDisabled(): boolean {
    return this.isLogoutInProgress;
  }

  // PROPS

  // WATCH

  // HOOKS

  // METHODS

  private toggleTimezoneHint(bool: boolean): void {
    this.isTimezoneHintVisible = bool;
  }

  private transformEventDate(date: string): string {
    return momentTimezone.utc(date).tz(this.event.time_region).format('DD MMM YYYY HH:mm');
  }

  private async logout(): Promise<void> {
    this.isLogoutInProgress = true;
    await this.$store.dispatch('authStore/authLogout');
    this.isLogoutInProgress = false;
  }

}
