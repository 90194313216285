import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { TNote } from '@/_types/store/note.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';
import NotesHelper from '@/_helpers/notes.helper';

export type TGetNotesQueryParams = {
  user_id?: number;
  external_id?: string;
  offset?: number;
}

export type TGetNotesParams = {
  eventId: number;
  queryParams?: TGetNotesQueryParams;
}

export class NotesApi extends BaseApi {

  public async getNotes(params: TGetNotesParams, cancelToken?: CancelToken): Promise<TApiListResponse<TNote>> {
    const { eventId, queryParams } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/note/list/all`,
      method: 'GET',
      cancelToken,
    };

    if (queryParams) {
      config.params = queryParams;
    }

    let response;
    try {
      response = await this.axios.request<TApiListResponse<any>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    response.data.List = response.data.List.map(item => {
      return NotesHelper.responseToNoteItemConverter(item);
    });

    return response.data;
  }

}

const notesApi = new NotesApi();
export default notesApi;
