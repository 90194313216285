import { TNote } from '@/_types/store/note.type';

export enum TabNames {
  NONE = '_nothing_',
  NOTIFICATIONS = 'notifications',
  CORRESPONDENCE = 'correspondence',
  SCHEDULE = 'SCHEDULE',
  CONTACT = 'contact',
}

export type TSideBarRightStoreState = {
  isOpen: boolean;
  isSideBarRightComponentPresent: boolean;
  activeTabName: TabNames;
  selectedContactNotes: TNote[];
  isUserSettingsOpen: boolean;
  isQRCodePopupVisible: boolean;
}
